import React from 'react'
import Helmet from 'react-helmet'
import styles from './about.module.css'

import Layout from '../components/layout'

const AboutPage = () => (
  <Layout>
    <Helmet
      title={`About - Remote Job Clubs: Work from Anywhere`}
    />
    <div className={styles.root}>
      <h1>Hi there. <span aria-label="hand" role="img">👋</span></h1>
      <p>Thanks for stopping by. I’m <a href="https://sanjeevan.co.uk">Sanj</a>, and I have one goal: Make you better off than you were before you met me.</p>

      <p>Remote Jobs Club will help you find a remote job that will allow you to work from home, or from anywhere in the world. Once a week, I'll send you an email with the best remote job positions depending on your preferences.</p>

      <p>The newsletter is free and in the future, it will be supported by sponsors.</p>

      <p>If you have any questions or comments, just send me an email. I love ideas to improve Remote Jobs Club, so don’t hesitate to send those!</p>

      <p>Sanj</p>
    </div>
  </Layout>
)

export default AboutPage
